export default [
  'angry',
  'dizzy',
  'flushed',
  'frown',
  'frown-open',
  'grimace',
  'grin',
  'grin-alt',
  'grin-beam',
  'grin-beam-sweat',
  'grin-hearts',
  'grin-squint',
  'grin-squint-tears',
  'grin-stars',
  'grin-tears',
  'grin-tongue',
  'grin-tongue-squint',
  'grin-tongue-wink',
  'grin-wink',
  'kiss',
  'kiss-beam',
  'kiss-wink-heart',
  'laugh',
  'laugh-beam',
  'laugh-squint',
  'laugh-wink',
  'meh',
  'meh-blank',
  'meh-rolling-eyes',
  'sad-cry',
  'sad-tear',
  'smile',
  'smile-beam',
  'smile-wink',
  'surprise',
  'tired'
]
